var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',{attrs:{"id":"dashboard"}},_vm._l((_vm.items),function(item,index){return _c('CCol',{key:index,attrs:{"sm":"12","md":"6","lg":"2"}},[_c('CCard',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip.hover",value:({content: ("" + (item.updated_at)), placement: 'bottom'}),expression:"{content: `${item.updated_at}`, placement: 'bottom'}",modifiers:{"hover":true}}]},[_c('CCardBody',[_c('router-link',{attrs:{"to":'/data/proccesses/' + item.id + '/details'}},[_c('div',{staticClass:"text-muted font-weight-bold"},[_vm._v(_vm._s(item.name))])]),_c('div',{staticClass:"text-value-lg",staticStyle:{"font-size":"20px"},attrs:{"id":("id-" + (item.id))},on:{"click":function($event){_vm.color_picker=true;_vm.id="id-" + (item.id)}}},[_vm._v(_vm._s(item.last_data))])],1)],1)],1)}),1),_c('CModal',{attrs:{"title":"Styles","size":"sm","color":"primary","show":_vm.color_picker},on:{"update:show":function($event){_vm.color_picker=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('CButton',{attrs:{"color":"secondary"},on:{"click":_vm.set_default}},[_vm._v("Default")]),_c('CButton',{attrs:{"color":"primary"},on:{"click":_vm.set_styles}},[_vm._v("Submit")])]},proxy:true}])},[_c('color-picker',{model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}}),_c('br'),_c('CSelect',{attrs:{"options":[
				{value: 'Oswald', label:'Oswald'},
				{value: 'Teko', label:'Teko'},
				{value: 'Yanone Kaffeesatz', label:'Yanone Kaffeesatz'},
				{value: 'Cairo', label:'Cairo'},
				{value: 'Rajdhani', label:'Rajdhani'},
				{value: 'Julius Sans One', label:'Julius Sans One'},
				{value: 'Syncopate', label:'Syncopate'},
				{value: 'Reem Kufi', label:'Reem Kufi'},
				{value: 'Lekton', label:'Lekton'} ],"placeholder":"Please Select Font Family"},on:{"change":function($event){_vm.font_family=$event.target.value}},model:{value:(_vm.font_family),callback:function ($$v) {_vm.font_family=$$v},expression:"font_family"}}),_c('CSelect',{attrs:{"options":[
				{value: '14', label:'14'},
				{value: '15', label:'15'},
				{value: '16', label:'16'},
				{value: '18', label:'18'},
				{value: '20', label:'20'},
				{value: '22', label:'22'},
				{value: '24', label:'24'},
				{value: '26', label:'26'},
				{value: '28', label:'28'},
				{value: '32', label:'32'} ],"placeholder":"Please Select Font Size"},on:{"change":function($event){_vm.font_size=$event.target.value}},model:{value:(_vm.font_size),callback:function ($$v) {_vm.font_size=$$v},expression:"font_size"}}),_c('CInput',{attrs:{"placeholder":"Positoin Number","maxlength":"3","onkeypress":"return event.charCode > 48"},model:{value:(_vm.position),callback:function ($$v) {_vm.position=$$v},expression:"position"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }